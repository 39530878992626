import React from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import required modules
import { Pagination, Navigation } from 'swiper/modules';

const Videos = () => {
  const videos = [
    'https://www.youtube.com/embed/k8zDyM5vsj8?rel=0',
    'https://www.youtube.com/embed/hi3Rhuzoxrk?rel=0',
    'https://www.facebook.com/plugins/video.php?height=315&href=https%3A%2F%2Fwww.facebook.com%2F100090068796424%2Fvideos%2F279153251566849%2F&show_text=false&width=560&t=0',
  ];

  return (
    <div className='max-w-2xl mx-auto rounded-md overflow-hidden mb-7' style={{ height: '400px' }}>
      <Swiper
        slidesPerView={1}
        spaceBetween={30}
        loop={true}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Pagination, Navigation]}
        className="mySwiper"
      >
        {videos.map((video, index) => (
          <SwiperSlide key={index}>
            <iframe
              title={`Video ${index + 1}`}
              src={video}
              width="100%"
              height="100%"
              frameBorder="0"
              allowFullScreen
            ></iframe>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Videos;
