import React from "react";

const Extras = () => {
    return (
        <>
            <div className="max-w-2xl mx-auto bg-gray-200 shadow-md rounded-md overflow-hidden p-10 mb-7">
                <div className="centered-video">
                    <iframe
                        className="shadow-lg rounded-md"
                        src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2F100090068796424%2Fvideos%2F877003067333812%2F&show_text=false&width=560&t=0"
                        width="100%"
                        height="314"
                        scrolling="no"
                        frameBorder="0"
                        allowFullScreen={true}
                        allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                    ></iframe>
                </div>

                <p>
                    <ul className="list-disc list-inside">
                        <li>1ra Noche del 12° Congreso de Jóvenes Esperanza Viva!!</li>
                        <li>Predicador invitado desde Vaqueria.</li>
                        <li>Presbítero: Elmer González</li>
                    </ul>
                </p>
            </div>

            <div className="max-w-2xl mx-auto bg-gray-200 shadow-md rounded-md overflow-hidden p-10 mb-7">
                <div className="centered-video">
                    <iframe
                        className="shadow-lg rounded-md"
                        src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2F100090068796424%2Fvideos%2F204221042677869%2F&show_text=false&width=560&t=0"
                        width="100%"
                        height="314"
                        scrolling="no"
                        frameBorder="0"
                        allowFullScreen={true}
                        allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                    ></iframe>
                </div>

                <p>
                    <ul className="list-disc list-inside">
                        <li>2do y último día del 12° Congreso de Jóvenes Esperanza Viva!!</li>
                        <li>Predicador invitado, desde Argentina</li>
                        <li>Pastor: Damian Esquivel</li>
                    </ul>
                </p>
            </div>

            <div className="max-w-2xl mx-auto bg-gray-200 shadow-md rounded-md overflow-hidden p-10 mb-7">
                <div className="centered-video">
                    <iframe
                        className="shadow-lg rounded-md"
                        src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2F100090068796424%2Fvideos%2F1051535976038566%2F&show_text=false&width=560&t=0"
                        width="100%"
                        height="314"
                        scrolling="no"
                        frameBorder="0"
                        allowFullScreen={true}
                        allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                    ></iframe>
                </div>

                <p>
                    <ul className="list-disc list-inside">
                        <li>1° Noche del Congreso Del Circulo de Oración: Fuego en el Altar</li>
                    </ul>
                </p>
            </div>

            <div className="max-w-2xl mx-auto bg-gray-200 shadow-md rounded-md overflow-hidden p-10 mb-7">
                <div className="centered-video">
                    <iframe
                        className="shadow-lg rounded-md"
                        src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2F100090068796424%2Fvideos%2F1550921592401784%2F&show_text=false&width=560&t=0"
                        width="100%"
                        height="314"
                        scrolling="no"
                        frameBorder="0"
                        allowFullScreen={true}
                        allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                    ></iframe>
                </div>

                <p>
                    <ul className="list-disc list-inside">
                        <li>2da y Ultima noche del Congreso de las hermanas del Circulo de Oración: Fuego en el Altar.</li>
                    </ul>
                </p>
            </div>
        </>
    );
};

export default Extras;