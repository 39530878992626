import React from "react";
import { Link } from "react-scroll";

const Header = () => {
    return (
        <header className="bg-gray-500 shadow dark:bg-gray-800 text-white">
            <div className="text-center">
                <h1 className="p-5 text-3xl font-bold">Iglesia M.E.M Alto Paraná</h1>
                <p className="font-bold">
                    Somos una organización Cristiana, nos encargamos de expandir la palabra
                    de esperanza y salvación.
                </p>
            </div>
            <nav>
                <div className="container flex items-center justify-center p-6 mx-auto text-gray-600 capitalize dark:text-gray-300">
                    <Link
                        to="inicio" // Reemplaza "inicio" con el nombre del elemento de destino (ID) de la sección
                        spy={true}
                        smooth={true}
                        offset={-70} // Ajusta este valor según la altura de tu encabezado
                        duration={500}
                        className="border-b-2 border-transparent text-white dark:hover:text-gray-400 hover:border-blue-500 mx-1.5 sm:mx-6"
                    >
                        <i className="fa-solid fa-house"></i> Inicio
                    </Link>
                    <Link
                        to="programacion"
                        spy={true}
                        smooth={true}
                        offset={-70}
                        duration={500}
                        className="border-b-2 border-transparent text-white dark:hover:text-gray-400 hover:border-blue-500 mx-1.5 sm:mx-6"
                    >
                        <i className="fa-solid fa-calendar"></i> Programación
                    </Link>
                    <Link
                        to="biblia"
                        spy={true}
                        smooth={true}
                        offset={-70}
                        duration={500}
                        className="border-b-2 border-transparent text-white dark:hover:text-gray-400 hover:border-blue-500 mx-1.5 sm:mx-6"
                    >
                        <i className="fa-solid fa-book-bible"></i> Biblia
                    </Link>
                    <Link
                        to="contacto"
                        spy={true}
                        smooth={true}
                        offset={-70}
                        duration={500}
                        className="border-b-2 border-transparent text-white dark:hover:text-gray-400 hover:border-blue-500 mx-1.5 sm:mx-6"
                    >
                        <i className="fa-solid fa-envelope"></i> Contacto
                    </Link>
                </div>
            </nav>
        </header>
    );
};

export default Header;
