import React from "react";
import ImgWhatsapp from "../assets/whatsapp.png";
import ImgYoutube from "../assets/youtube.png";
import ImgFacebook from "../assets/facebook.png";

const Footer = () => {
    return (
        <footer>
            <div className="bg-gray-100 text-black py-8 mx-auto flex flex-col items-center">
                <h2 className="text-2xl font-bold mb-4">Síguenos en nuestras redes</h2>
                <div className="flex space-x-4">
                    {/* Botón de WhatsApp */}
                    <a
                        href="https://wa.me/+595973667709"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-white hover:text-gray-300"
                    >
                        <img src={ImgWhatsapp} alt="WhatsApp" className="w-8 h-8" />
                    </a>
                    {/* Botón de YouTube */}
                    <a
                        href="https://www.youtube.com/@N3750NVALLEJOS"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-white hover:text-gray-300"
                    >
                        <img src={ImgYoutube} alt="YouTube" className="w-8 h-8" />
                    </a>
                    {/* Botón de Facebook */}
                    <a
                        href="https://www.facebook.com/profile.php?id=100090068796424&mibextid=cejktS"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-white hover:text-gray-300"
                    >
                        <img src={ImgFacebook} alt="Facebook" className="w-8 h-8" />
                    </a>
                </div>
            </div>
            <div className="bg-gray-500 text-white py-2 text-center">
                <p>&copy; 2023 Ciudad del Este, Alto Parana, Paraguay</p>
            </div>
        </footer>
    );
};

export default Footer;
