import React from "react";
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import required modules
import { Pagination, Navigation } from 'swiper/modules';

// import images
import Img01 from "../assets/swiper-01.jpeg";
import Img02 from "../assets/swiper-02.jpeg";
import Img03 from "../assets/swiper-03.jpeg";
import Img04 from "../assets/swiper-04.jpeg";
import Img05 from "../assets/swiper-05.jpeg";
import Img06 from "../assets/swiper-06.jpeg";
import Img07 from "../assets/swiper-07.jpeg";

const Programacion = () => {
    return (
        <>
            <div id="programacion" className="max-w-2xl mx-auto bg-white shadow-md rounded-md overflow-hidden p-10 mb-7">
                <h1 className="text-4xl font-bold py-4 p-3 text-center">Programación</h1>
                <h2 className="text-xl font-semibold p-3 text-center">Descubre nuestros programas llenos de contenido inspirador y mensajes de fe.</h2>
                <div className="max-w-xl mx-auto bg-white shadow-md rounded-md overflow-hidden mb-7">
                    <Swiper
                        slidesPerView={1}
                        spaceBetween={30}
                        loop={true}
                        pagination={{
                            clickable: true,
                        }}
                        navigation={true}
                        modules={[Pagination, Navigation]}
                        className="mySwiper"
                    >
                        <SwiperSlide><img src={Img01} alt="Swiper-01" className="w-500 h-500" /></SwiperSlide>
                        <SwiperSlide><img src={Img02} alt="Swiper-02" className="w-500 h-500" /></SwiperSlide>
                        <SwiperSlide><img src={Img03} alt="Swiper-03" className="w-500 h-500" /></SwiperSlide>
                        <SwiperSlide><img src={Img04} alt="Swiper-04" className="w-500 h-500" /></SwiperSlide>
                        <SwiperSlide><img src={Img05} alt="Swiper-05" className="w-500 h-500" /></SwiperSlide>
                        <SwiperSlide><img src={Img06} alt="Swiper-06" className="w-500 h-500" /></SwiperSlide>
                        <SwiperSlide><img src={Img07} alt="Swiper-06" className="w-500 h-500" /></SwiperSlide>
                    </Swiper>
                </div>

                <div className="max-w-xl mx-auto bg-gray-200 shadow-md rounded-md overflow-hidden">
                    <h2 className="text-center text-2xl font-bold mb-4 mt-4">Programación Semanal</h2>
                    <ul className="space-y-4 p-4">
                        <li>
                            <strong><i className="fa-solid fa-arrow-right"></i> Una luz en tu camino</strong> - Todos los Domingos de 09:00hs a 11:00hs - Conducen: Las hnas Norma Cáceres y Sandra Recalde.
                        </li>
                        <li>
                            <strong><i className="fa-solid fa-arrow-right"></i> El buen samaritano</strong> - Los días: Miércoles y Viernes de 10:00hs a 12:00hs - Conducen: Los hermanos Mónica y Mathias.
                        </li>
                        <li>
                            <strong><i className="fa-solid fa-arrow-right"></i> Restauración y paz</strong> - Los días Miércoles de 13:00hs a 15:00hs - Conduce: El Jóven hno Cristian Julian.
                        </li>
                        <li>
                            <strong><i className="fa-solid fa-arrow-right"></i> Una esperanza en Cristo</strong> - Los Miércoles de 18:30hs a 20:30hs y los Sábados de 14:00s a 16:00hs - Conduce: El hno Julio González.
                        </li>
                        <li>
                            <strong><i className="fa-solid fa-arrow-right"></i> Enviame a mi</strong> - Los día Martes y Jueves de 10:00hs a 12:00hs de la mañana... Conduce el Pastor Francisco López.
                        </li>
                        <li>
                            <strong><i className="fa-solid fa-arrow-right"></i> Refugio en la tormenta</strong> -Los dias lunes desde 20:00hs a 22:00hs y los Sabados de 10:00hs a 12:00hs - Conduce: El Joven Elias Leiva.
                        </li>
                        <li>
                            <strong><i className="fa-solid fa-arrow-right"></i> El tiempo esta cerca</strong> -Los dias lunes y viernes desde 18:00hs a 20:00hs - Conduce: La joven hermana Diana Ovelar.
                        </li>
                    </ul>
                </div>
            </div>
        </>
    );
};

export default Programacion;
