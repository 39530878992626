import React from "react";
import ImgBook from "../assets/book.png";

const Biblia = () => {
    const abrirNuevaPestana = (url) => {
        window.open(url, "_blank");
    };

    return (
        <div id="biblia" className="max-w-2xl mx-auto bg-gray-200 text-black shadow-md rounded-md overflow-hidden p-10 mb-7">
            <h1 className="text-3xl font-bold py-4 text-center">El Poder Redentor de la Sangre del Señor</h1>
            <div className="flex justify-center items-center">
                <a
                    href="#"
                    onClick={() => abrirNuevaPestana("https://www.bibliaonlinerv1960.com/")}>
                    <img
                        src={ImgBook}
                        alt="Imagen de la Biblia"
                        className="w-32 h-32"
                    />
                </a>
            </div>


            {/* Enlaces a diferentes secciones de la Biblia */}
            <div className="my-4">
                <a
                    href="#"
                    onClick={() => abrirNuevaPestana("https://www.bibliaonlinerv1960.com/1-juan-1/")}>
                    <i className="fa-solid fa-feather-pointed"></i> <strong>1ª Juan 1:7</strong>
                </a>
                <p>"Pero si andamos en luz, como él está en luz, tenemos comunión unos con otros, y la sangre de Jesucristo su Hijo nos limpia de todo pecado."</p>
            </div>
            <div className="my-4">
                <a
                    href="#"
                    onClick={() => abrirNuevaPestana("https://www.bibliaonlinerv1960.com/efesios-1/")}>
                    <i className="fa-solid fa-feather-pointed"></i> <strong>Efesios 1:7</strong>
                </a>
                <p>"En quien tenemos redención por su sangre, el perdón de pecados según las riquezas de su gracia."</p>
            </div>
            <div className="my-4">
                <a
                    href="#"
                    onClick={() => abrirNuevaPestana("https://www.bibliaonlinerv1960.com/apocalipsis-1/")}>
                    <i className="fa-solid fa-feather-pointed"></i> <strong>Apocalipsis 1:5</strong>
                </a>
                <p>Y de Jesucristo el testigo fiel, el primogénito de los muertos, y el soberano de los reyes de la tierra. Al que nos amó, y nos lavó de nuestros pecados con su sangre."</p>
            </div>
            <div className="my-4">
                <a
                    href="#"
                    onClick={() => abrirNuevaPestana("https://www.bibliaonlinerv1960.com/hebreos-12/")}>
                    <i className="fa-solid fa-feather-pointed"></i> <strong>Hebreos 12:24</strong>
                </a>
                <p>"A Jesús el Mediador del nuevo pacto, y a la sangre rociada que habla mejor que la de Abel."</p>
            </div>
        </div>
    );
};

export default Biblia;
