import React from "react";

const Horario = () => {
    return (
        <div className="max-w-2xl mx-auto bg-gray-200 shadow-md rounded-md overflow-hidden p-10 mb-7">
            <h1 className="text-4xl font-bold py-4 p-3 text-center">Dias de Culto</h1>
            <div className="max-w-xl mx-auto bg-white shadow-md rounded-md overflow-hidden mb-7">
                <div className="p-4 bg-gray-500 text-white">
                    <h2 className="text-xl font-semibold">Calendario de Culto</h2>
                </div>
                <div className="p-4">
                    <ul className="space-y-2">
                        <li>
                            <span className="font-semibold">Martes:</span> 19:30 hs.
                        </li>
                        <li>
                            <span className="font-semibold">Jueves:</span> 19:30 hs.
                        </li>
                        <li>
                            <span className="font-semibold">Sábado:</span> 19:30 hs.
                        </li>
                        <li>
                            <span className="font-semibold">Domingo:</span> 18:30 hs.
                        </li>
                    </ul>
                </div>
            </div>
        </div>

    );

}

export default Horario;