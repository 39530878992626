import React from "react";
import Logo01 from "../assets/logo-01.png";

const Hero = () => {
    return (
        <div id="inicio" className="min-h-screen flex items-center justify-center mb-7">
            <div className="text-center">
                <h1 className="text-4xl font-bold mb-4">
                    Iglesia Evangelica Ministerio de Evangelismo y Misiones
                </h1>
                <div className="flex justify-center items-center">
                    <img
                        src={Logo01}
                        alt="Logo de la iglesia M.E.M"
                        className="w-64 h-64"
                    />
                </div>
                <div className="flex justify-center items-center">
                    <p className="max-w-xl">
                        Somos una entidad Cristiana, con el compromiso de transmitir un
                        mensaje de fe, Amor y Esperanza. A un mundo carente y sufrido,
                        venimos con el mensaje del Evangelio de nuestro Señor Jesucristo.
                        No todo está perdido aún hay "Esperanza" Emisora de la Iglesia MEM.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Hero;