import React from "react";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Layout from "./components/Layout";
import Home from "./views/Home";
import './App.css';

const App = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={ <Layout /> }>
              <Route index element={ <Home /> } />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default App;