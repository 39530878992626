import React from "react";

const Contacto = () => {
    return (
        <div id="contacto" className="max-w-2xl mx-auto bg-gray-200 shadow-md rounded-md overflow-hidden p-10 mb-7">
            <h1 className="text-4xl font-bold py-4 text-center">Contacto</h1>
            <p className="text-xl text-center">¡Estamos aquí para ayudarte!</p>
            <ul className="list-disc pl-5 mt-4">
                <li>
                    <span className="font-semibold">Correo Electrónico:</span>{" "}
                    <a href="mailto:contacto@vozdeesperanza.com">contacto@vozdeesperanza.com</a>
                </li>
                <li>
                    <span className="font-semibold">Teléfono:</span>{" "}
                    <a href="tel:+595973667709">+595 973 667 709</a>
                </li>
            </ul>
        </div>
    );
};

export default Contacto;
