import React from "react";
import Hero from "../components/Hero";
import Programacion from "../components/Programacion";
import Reproductor from "../components/Reproductor";
import Contacto from "../components/Contacto";
import Biblia from "../components/Biblia";
import Videos from "../components/Videos";
import Horario from "../components/Horario";
import Extras from "../components/Extras";


const Home = () => {
    return (
       <>
            <Hero />
            <Horario />
            <Reproductor />
            <Programacion />
            <Extras/>
            <Biblia />
            <Videos />
            <Contacto/>
       </>
    );
};

export default Home;
