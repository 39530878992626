import React from "react";

const RadioExterna = () => {
    return (
        <div className="iframe-container">
            <iframe
                title="Reproductor de Radio"
                src="https://www.zradios.com/reproductor/voz-de-esperanza"
                frameBorder="0"
                width="100%"
                height="370"
            ></iframe>
        </div>
    );
};

export default RadioExterna;
