import React, { useState, useRef } from "react";
import logo02 from "../assets/logo-02.png";
import RadioExterna from "./RadioExterna";

const Reproductor = () => {
    return (
        <div className="max-w-2xl mx-auto bg-white shadow-md rounded-md overflow-hidden p-10 mb-7 text-center">
            <h1 className="text-4xl font-bold py-4 p-3">Voz de Esperanza - Radio Cristiana</h1>
            <h3 className="text-xl font-semibold p-3">La frecuencia que te conecta al cielo</h3>
            <p>¡Sintoniza nuestras emisiones para inspirarte y encontrar esperanza en cada palabra!</p>
            <img
                src={logo02}
                alt="Portada del álbum"
                className="w-128 h-128 rounded-full mb-4"
            />
            <RadioExterna />
        </div>
    );
};

export default Reproductor;
